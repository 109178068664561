import { ServiceProvider } from 'consts';

interface IProviderFeatures {
  common: Array<FeatureCode>,

  outlook: Array<FeatureCode>,
  creativecloud: Array<FeatureCode>,
  chromium: {
    common: Array<FeatureCode>,
    firefox: Array<FeatureCode>,
    chrome: Array<FeatureCode>
  },
  teams: Array<FeatureCode>,
}

export interface IEntityLayout {
  entityColor: string;
  idFormat: string;
}

export interface IAppFeatures {
  common: Array<FeatureCode>,
  dev: Array<FeatureCode>,
  web: Array<FeatureCode>,
  office: Array<FeatureCode>,
  outlook: Array<FeatureCode>,
  project: Array<FeatureCode>,
  creativecloud: Array<FeatureCode>,
  chromium: {
    common: Array<FeatureCode>,
    firefox: Array<FeatureCode>,
    chrome: Array<FeatureCode>
  },
  teams: Array<FeatureCode>,
  providers: Array<{
    provider: ServiceProvider,
    features: IProviderFeatures,
    layout: IEntityLayout,
    thumbnailUrl: string,
    logoUrl: string
  }>
}

export type FeatureCode =
  // Debug mode
  "home" |

  // Linked pane
  "linked.pane.tab.by.context" |
  "linked.pane.tab.by.contact" |
  "linked.pane.tab.recommends" |

  "linked.pane.convert.to" |
  "linked.pane.export.to" |
  "linked.pane.send.to" |
  "linked.pane.link.to" |
  "linked.pane.checklist" |
  "linked.pane.timers" |

  "new.activity" |
  "favorites" |

  // Nav panel
  "nav.panel.home" |
  "nav.panel.post.time.entry" |

  // Toolbar
  "toolbar.settings.open.popup" |

  // Settings
  "settings.preferences" |
  "settings.default.values" |
  "settings.workspace.required" |
  "settings.organization" |

  // Account

  "account.create.defaultWebRoot" |

  // Activity details
  "details.tab.description" |
  "details.tab.messages" |
  "details.tab.emails" |
  "details.set.dates.ep.popup" |
  "details.set.dates.simple.fields" |
  "details.description.paste.from.email.body" |
  "details.description.paste.from.selected.body" |
  "details.additional.fields" |
  "details.additional.fields.estimated.hours" |
  "details.additional.fields.hours.left" |
  "details.additional.fields.budget" |
  "details.additional.fields.category" |
  "details.assignees.single" |
  "details.assignees.multiple" |
  "details.description" |
  "details.parent" |
  "messages.set.approvers" |
  "messages.post.time.entry" |
  "messages.ai.rewrite" |
  "messages.screenshot" |
  "messages.export.to.pdf" |
  "messages.export.to.mhtml" |
  "details.assignees" |
  "details.progress" |
  "messages.upload" |
  "messages.upload.attachments.from.email" |
  "messages.upload.attachments.from.adobe.document" |
  "messages.upload.attachments.pdf" |
  "messages.upload.attachments.mhtml" |
  "messages.filesize" |
  "time.entry.activity" |
  "time.entry.billable" |
  "time.entry.user.must.be.assigneed" |
  "messages.is.private" |
  "details.description.format" |
  "details.description.readonly" |
  "details.priority" |
  "details.start.date" |
  "details.end.date" |
  "details.description.no.utf.4bits" |

  // License
  "purchase.license"
;

const common: Array<FeatureCode> = [
  "linked.pane.link.to",
  "linked.pane.send.to",
  "linked.pane.tab.by.context",

  "linked.pane.checklist",
  "favorites",
  "new.activity",
  "details.tab.messages",
  "details.tab.description",
  "details.assignees",
  "details.description",
  "details.parent",
  "settings.preferences",
  "settings.default.values",
  "settings.organization",
  
  
];

const web: Array<FeatureCode> = [
  "linked.pane.send.to",
  "linked.pane.timers",
  "toolbar.settings.open.popup",
  "purchase.license"
];

const outlook: Array<FeatureCode> = [
  "details.tab.emails",
  "linked.pane.tab.by.contact",
  "linked.pane.convert.to",
  "linked.pane.send.to",
  "details.description.paste.from.email.body",
  "messages.upload",
  "messages.upload.attachments.from.email",
  "purchase.license",
  "messages.ai.rewrite"
];

const office: Array<FeatureCode> = [
  "linked.pane.convert.to",
  "linked.pane.send.to",
  "messages.upload.attachments.pdf",
  "details.description.paste.from.selected.body",
  "purchase.license",

];

const project: Array<FeatureCode> = [
  "linked.pane.convert.to",
  "linked.pane.send.to",
  "details.description.paste.from.selected.body",
  "purchase.license",

];

const creativecloud: Array<FeatureCode> = [
  "linked.pane.export.to",
  "messages.upload.attachments.from.adobe.document",
  "purchase.license",

];

const teams: Array<FeatureCode> = [
  "linked.pane.send.to"
];

const chromium = {
  common: [
    "messages.screenshot",
    "paste.description.from.body",
    "linked.pane.send.to",
    "purchase.license"
  ],
  firefox: ["messages.upload", "messages.upload.attachments.mhtml"],
  chrome: ["messages.upload", "messages.upload.attachments.mhtml"]
} as {
  common: Array<FeatureCode>,
  firefox: Array<FeatureCode>,
  chrome: Array<FeatureCode>
};

const dev: Array<FeatureCode> = [
  "linked.pane.timers",
  "toolbar.settings.open.popup",
];

export const AppFeatures: IAppFeatures = {
  common,
  dev,
  // * testing
  // common: common.concat(chromium.common).concat(chromium.chrome),
  // common: common.concat(outlook),
  // common: common.concat(creativecloud),

  web: web,
  outlook: outlook,
  office: office,
  project: project,
  creativecloud: creativecloud,
  chromium: chromium,
  teams: teams,

  providers: [
    {
      provider: ServiceProvider.EasyProjects,
      thumbnailUrl: "https://files.ahau260.com/images/birdview/32.png",
      logoUrl: "https://files.ahau260.com/images/birdview/128.png",
      features: {
        common: [
          'details.set.dates.ep.popup',
          'details.additional.fields.estimated.hours',
          'details.additional.fields.hours.left',
          'details.additional.fields.budget',
          'details.additional.fields.category',
          'details.assignees.multiple',
          'messages.upload',
          'messages.set.approvers',
          'time.entry.billable',
          "time.entry.user.must.be.assigneed",
          'details.progress',
          'details.priority',
          'details.start.date',
          'details.end.date',
          'details.additional.fields',
          'messages.filesize',
          'messages.post.time.entry',
          'nav.panel.post.time.entry',
        ],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      } as IProviderFeatures,
      layout: {
        entityColor: 'rgba(146, 100, 205, 0.8)',
        idFormat: 'BV-{id}',
      },
    },
    {
      provider: ServiceProvider.Redmine,
      thumbnailUrl: "https://files.ahau260.com/images/redmine/32.png",
      logoUrl: "https://files.ahau260.com/images/redmine/128.png",
      features: {
        common: [
          'details.set.dates.simple.fields',
          'details.assignees.single',
          'details.additional.fields.estimated.hours',
          'time.entry.activity',
          'messages.is.private',
          'details.description.format',
          'details.progress',
          'details.priority',
          'details.start.date',
          'details.end.date',
          'details.additional.fields',
          'messages.filesize',
          'messages.post.time.entry',
          'nav.panel.post.time.entry',
          "details.description.no.utf.4bits"
        ],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      } as IProviderFeatures,
      layout: {
        entityColor: 'rgba(156, 0, 0, 0.7)',
        idFormat: 'RM-{id}',
      },
    },
    {
      provider: ServiceProvider.Asana,
      thumbnailUrl: "https://files.ahau260.com/images/asana/32.png",
      logoUrl: "https://files.ahau260.com/images/asana/128.png",
      features: {
        common: [
          'details.set.dates.simple.fields',
          'details.assignees.single',
          'details.start.date',
          'details.end.date',
          'settings.workspace.required',
          "account.create.defaultWebRoot",
        ],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      } as IProviderFeatures,
      layout: {
        entityColor: 'rgba(252, 99, 107, 1)',
        idFormat: 'AS-{id}',
      },
    },
    {
      provider: ServiceProvider.Jira,
      thumbnailUrl: "https://files.ahau260.com/images/jira/32.png",
      logoUrl: "https://files.ahau260.com/images/jira/128.png",
      features: {
        common: [
          //Activity details
          'details.tab.description',
          'details.set.dates.simple.fields',
          'details.assignees.single',
          'details.start.date',
          'details.end.date',
          'details.additional.fields.budget',
          'details.additional.fields.estimated.hours',
          'details.priority',
        ],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      } as IProviderFeatures,
      layout: {
        entityColor: 'rgba(38,132,255,1)',
        idFormat: 'J-{id}',
      },
    },
    {
      provider: ServiceProvider.Fake,
      features: {
        common: [],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      },
    },
    {
      provider: ServiceProvider.HubSpot,
      thumbnailUrl: "https://files.ahau260.com/images/hubspot/32.png",
      logoUrl: "https://files.ahau260.com/images/hubspot/128.png",
      features: {
        common: [
          "account.create.defaultWebRoot"
        ],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      },
      layout: {
        entityColor: 'rgba(252, 99, 107, 1)',
        idFormat: 'H-{id}',
      },
    },
    {
      provider: ServiceProvider.Office365,
      thumbnailUrl: "https://files.ahau260.com/images/microsoft365/32.png",
      logoUrl: "https://files.ahau260.com/images/microsoft365/128.png",
      features: {
        common: ["account.create.defaultWebRoot"],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      },
      layout: {
        entityColor: 'rgba(252, 99, 107, 1)',
        idFormat: 'O-{id}',
      },
    },
    {
      provider: ServiceProvider.Google,
      thumbnailUrl: "https://files.ahau260.com/images/google/32.png",
      logoUrl: "https://files.ahau260.com/images/google/128.png",
      features: {
        common: ["account.create.defaultWebRoot"],
        outlook: outlook,
        creativecloud: creativecloud,
        chromium: chromium,
        teams: teams,
      },
      layout: {
        entityColor: 'rgba(252, 99, 107, 1)',
        idFormat: 'O-{id}',
      },
    },
  ],
} as IAppFeatures
